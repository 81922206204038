<template>
  <div class="systemManage-create preview-img">
    <el-dialog 
      v-model="dialogVisible" 
      :title="$t('button.Preview')" 
      :before-close="handleClose" 
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="600px"
    >
      <div class="image-container">
        <div class="item" v-if="imageUrl">
          <div class="img-container">
            <img @click="handleImgClick(imageUrl)" :src="imageUrl" class="img" />
          </div>
          <div class="img-desc">{{$t('images.Original')}}</div>
        </div>
        <div v-for="(item,index) in dataList" :key="index" class="item">
          <div class="img-container">
            <img @click="handleImgClick(item.imageUrl)" :src="item.imageUrl" class="img" />
          </div>
          <div class="img-desc">{{item.colorDesc}}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { getImageInfor } from "@/libs/api/system-management-images";

  export default {
    name:'previewImg',
    components: {
    },
    props: {},
    data() {
      return {
        userData:JSON.parse(localStorage.getItem('userData')),
        dialogVisible:false,
        dataList:null,
        imageUrl:''
      }
    },
    created(){

    },
    mounted(){
      
    },
    methods: {
      // 打开弹窗
      open(data){
        this.dialogVisible = true
        this.getImageList(data.storeImageId)
      },
      // 关闭弹窗
      handleClose(){
        this.dialogVisible = false
        this.imageUrl = ''
        this.dataList = null
      },
      // 请求--获取数据
      async getImageList(data){
        let params = {
          imageId:data
        }
        try {
          let res = await getImageInfor(params)
          let response = res.data
          if(response.state){
            console.log(response)
            this.dataList = response.data.colorImageList
            this.imageUrl = response.data.imageUrl
          } else{
            this.$message.error(response.message);
          }
        } catch(err) {
          this.$message.error(err.message)
        }
      },
      // 点击查看图片
      handleImgClick(url){
        window.open(url)
      },
    }
  }
</script>
<style lang="scss" scoped>
  .preview-img{
    .image-container{
      // width: 480px;
      margin: 0 auto;
      min-height: 300px;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      .item{
        width: 33.33%;
        padding: 0 15px;
        box-sizing: border-box;
        .img-container{
          width: 100%;
          height: 120px;
          background: whitesmoke;
          border-radius: 6px;
          overflow: hidden;
          .img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            cursor: pointer;
          }
        }
        .img-desc{
          padding: 14px 0;
        }
      }
    }
  }
</style>