import { reactive } from '@vue/composition-api'

const store = reactive({
  imageGroupFn:null, // 重新加载素材组方法
  setImageGroupFn(fn){ // 设置-- 重新加载素材组
    this.imageGroupFn = fn
  },
  reloadImageGroupFn(){ // 调用-- 加载素材组方法
    this.imageGroupFn()
  },
})
export default store