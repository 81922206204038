<template>
  <div class="systemManage-create add-group">
    <el-dialog 
      v-model="dialogVisible" 
      :title="$t('images.Add Group')" 
      :before-close="handleClose" 
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
    >
      <div class="form-box">
        <el-form 
          label-width="100px"
          style="width: 100%"
          :model="formData"
          ref="form" 
          :rules="rules"
          size="small"
        >
          <el-form-item :label="$t('images.Group')" prop="groupName">
            <el-input 
              v-model="formData.groupName" 
              placeholder=""
              clearable 
            />
          </el-form-item>

          <!-- 保存按钮 -->
          <div class="form-btn-section">
            <el-button color="#28C76F" size="small" @click="dialogVisible =false" >{{$t('button.Cancel')}}</el-button>
            <el-button color="#28C76F" size="small"  type="success" @click="onSubmit" > {{$t('button.Save')}}</el-button>
          </div>

        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { trim } from 'lodash';
  import { createImageGroup, updateImageGroup } from "@/libs/api/system-management-images";

  import imagesStore  from '../store/index'
  let loading;

  export default {
    name:'addGroup',
    components: {
    },
    props: {
      statusList: {
        type: Array
      },
      editData:{
        type:Object
      }
    },
    data() {
      return {
        imagesStore,
        userData:JSON.parse(localStorage.getItem('userData')),
        dialogVisible:false,
        initFormData:{
          imageGroupId:'',
          storeId:'',//门店id
          groupName:'',//分组名称
          groupOrder:'', //分组排序
          groupDesc:'', //分组描述
          merchantId:'', //	商户id
          isEffect:0 //是否有效，0：有效，1：无效	
        },
        formData:{
          imageGroupId:'',
          storeId:'',//门店id
          groupName:'',//分组名称
          groupOrder:'', //分组排序
          groupDesc:'', //分组描述
          merchantId:'', //	商户id
          isEffect:0 //是否有效，0：有效，1：无效	
        },
        rules:{
          groupName: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
          ], 
        },
        
      }
    },
    created(){

    },
    mounted(){
      
    },
    watch: {
      editData: function(val) {
        console.log('--watch--editData',val)
        if(val && val.imageGroupId){
          let data ={
            ...val
          }
          this.formData = data
        } else {
          let obj = {...this.initFormData}
          obj.storeId = val.storeId
          this.formData = obj
        }
        
      }
    },
    methods: {
      // 打开弹窗
      open(){
        this.dialogVisible = true
      },
      // 关闭弹窗
      handleClose(){
        this.$refs.form.resetFields()
        this.dialogVisible = false
      },
      // 表单提交
      onSubmit(){
        loading = this.$loading({
          text:"loading...",
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        })
        this.$refs.form.validate( valid => {
          if (valid) {
            console.log('表单校验通过',)
            this.createImageGroup()
          } else {
            loading.close()
            console.log('error submit!!');
            return false
          }
        })
      },
      // 请求--- 创建/更新 --素材组
      async createImageGroup(){
        let { imageGroupId, groupDesc, isEffect, groupOrder, groupName } = this.formData
        let userData = this.userData
        let params = {
          groupName:trim(groupName),//分组名称
          groupOrder, //分组排序
          groupDesc, //分组描述
        }
        let res = null
        try {
          if(imageGroupId){
            params.imageGroupId = imageGroupId
            res = await updateImageGroup(params)
          } else {
            params.isEffect = isEffect
            params.storeId = this.formData.storeId
            params.merchantId=userData.merchantId
            res = await createImageGroup(params)
          }
          let response = res.data
          if(response.state){
            let message =this.$t('commonMessage.CreateSucess')
            if(imageGroupId){
              message = this.$t('commonMessage.ModifySucess')
            }
            this.$message({
              message: message,
              type:'success',
            });
            this.dialogVisible = false
            this.$emit('onChange')
            loading.close()
            imagesStore.reloadImageGroupFn()
          } else{
            loading.close()
            this.$message.error(response.message);
          }
        } catch(err) {
          loading.close()
          this.$message.error(err.message)
        }
      },
    }
  }
</script>
<style lang="scss" scoped>
  .create-store{
    .form-box{
      width: 480px;
      margin: 0 auto;
      min-height: 300px;
    }
    .section-tit{
      position: relative;
      font-weight: 600;
      padding: 10px 10px 10px 20px;
      background: whitesmoke;
      margin-bottom: 20px;
      border-radius: 4px;
      .flag{
        position: absolute;
        width: 4px;
        height: 18px;
        border-radius: 2px;
        background: #35B871;
        top: 0px;
        bottom: 0px;
        left: 10px;
        margin: auto;
      }
    }

    .form-btn-section{
      width: 100%;
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      ::v-deep .el-button{
        width: 110px;
      }
    }
    // 重置样式
    ::v-deep .el-dialog{
      margin-top: 5vh !important;
    }
    ::v-deep .el-dialog__body{
      max-height: calc(82vh);
      overflow-y: scroll;
    }
    .el-select{
      width: 100%;
    }
    .el-button--success{
      width: 100%;
    }
  }
  .create-store.add-group{
    .form-box{
      width: 300px;
      margin: 0 auto;
      min-height: 200px;
      padding-top: 30px;
      box-sizing: border-box;
    }
  }
</style>