<template>
  <div class="systemManage-create create-image">
    <el-dialog 
      v-model="dialogVisible" 
      title="Create Image" 
      :title="formData.storeImageId ? $t('images.Image Details') : $t('images.Create Image')" 
      :before-close="handleClose"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="700px"
    >
      <div class="form-box">
        <el-form 
          label-width="110px"
          style="width: 100%"
          :model="formData"
          ref="form" 
          :rules="rules"
          size="small"
        >
          <!-- <div class="section-tit"><div class="flag"></div>基础信息</div> -->
          <el-form-item :label="$t('images.Groups')" prop="imageGroupId">
            <el-select v-model="formData.imageGroupId" placeholder="" size="small">
              <el-option
                v-for="item in groupList"
                :key="item.imageGroupId"
                :label="item.groupName"
                :value="item.imageGroupId"
              >
                <div class="d-flex justify-content-between select-group">
                  <div class="">{{item.groupName}}</div>
                  <div>
                    <span class="table-icon"  @click.stop="handleDelete(item)">
                      <i class="el-icon-delete"></i>
                    </span>
                    <span class="table-icon"  @click.stop="handleEdit(item)">
                      <i class="el-icon-edit-outline"></i>
                    </span>
                  </div>
                </div>
              </el-option>
            </el-select>
            <el-button type="primary" size="small" @click="handleAddGroup">
              <span style="padding:0 2px">
                <i class="el-icon-plus"></i>
              </span>
            </el-button>
          </el-form-item>
          <el-form-item :label="$t('itemsScenarios.ViewDialog.VisibleRange')" prop="isOpen" required>
            <el-radio-group v-model="formData.isOpen" >
              <el-radio :label="0">{{$t('itemsScenarios.ViewDialog.ThisStore')}}</el-radio>
              <el-radio :label="1">{{$t('itemsScenarios.ViewDialog.AllStore')}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <template v-if="formData.storeImageId === ''">
            <el-form-item :label="$t('images.Images')"  prop="imageUrl">
              <el-upload
                class="upload-demo"
                drag
                :file-list="fileList"
                :on-change="handleChange"
                :on-remove="removeImg"
                :http-request="uploadImage"
                :before-upload="beforeUpload"
                action="#"
                multiple
              >
                <i class="el-icon-upload" />
                <div class="el-upload__text">
                  {{$t('images.Drop file here or')}} <em>{{$t('images.click to upload')}}</em>
                  <div style="font-size: 12px;color: #ccc;">{{$t('images.MaxUploadSize')}}</div>
                </div>
                <!-- <template #tip>
                  <div class="el-upload__tip">
                    jpg/png files with a size less than 3M
                  </div>
                </template> -->
                <template #file>
                  <div></div>
                  <!-- <div style="max-height:300px">
                    <el-scrollbar style="height:100%">
                      <div class="file-list d-flex">
                        <div style="flex:1">
                          <i class="el-icon-picture"></i>
                          <span style="margin-left:4px">{{scop.file.name}}</span>
                        </div>
                        <div class="delete" @click="removeImg(scop.file,scop)"><i class="el-icon-delete"></i></div>
                      </div>
                    </el-scrollbar>
                  </div> -->
                </template>
              </el-upload>
              <div class="form-img-upload">
                <el-scrollbar style="height:100%">
                  <div class="file-list"
                    v-for="( item, index) in fileList"
                    :key="item.url"
                  >
                    <div style="flex:1">
                      <i class="el-icon-picture"></i>
                      <span style="margin-left:4px">{{item.name}}</span>
                    </div>
                    <div class="delete" @click="removeImg(index)"><i class="el-icon-delete"></i></div>
                  </div>
                </el-scrollbar>
              </div>
            </el-form-item>
            
            
          </template>
          <el-form-item :label="$t('images.Tag')"  prop="tag">
            <el-input 
              v-model="formData.imageTagName" 
              placeholder=""
              clearable 
              maxlength="100"
            />
            <el-button type="primary" size="small" @click="handleAddTag">{{$t('images.add')}}</el-button>
          </el-form-item>
          <el-form-item label="" prop="tags">
            <el-tag
              v-for="tag in imageTagsArr"
              :key="tag"
              class="mx-1"
              closable
              :disable-transitions="false"
              @close="handleDeleteTag(tag)"
            >
              {{ tag }}
            </el-tag>
          </el-form-item>

          <!-- 保存按钮 -->
          <div class="form-btn-section">
            <el-button color="#28C76F" size="small" @click="handleClose" > {{$t('button.Cancel')}}</el-button>
            <el-button color="#28C76F" size="small"  type="success" @click="onSubmit" >{{$t('button.Save')}}</el-button>
          </div>

        </el-form>
      </div>
    </el-dialog>

    <!-- 删除弹窗确认 -->
    <deleteConfirmDialog ref="deleteConfirmDialog" @onDeleteConfirm="imageDelete"></deleteConfirmDialog>

    <!-- 添加/编辑 素材组 -->
    <addGroup ref="addGroup" :editData="editGroupData" @onChange="addGroupSuccess"></addGroup>
  </div>
</template>
<script>
  import { trim } from 'lodash';
  import { getImageGroupList, uploadImage, createImageTag, batchCreateImage, updateImage, deleteImageGroup} from "@/libs/api/system-management-images";
  import addGroup from './addGroup'
  import deleteConfirmDialog from '@/components/delete-confirm-dialog/index'

  import imagesStore  from '../store/index'

  let loading;

  export default {
    name:'createCustomer',
    components: {
      addGroup,
      deleteConfirmDialog
    },
    props: {
      statusList: {
        type: Array
      },
      editData:{
        type:Object
      }
    },
    data() {
      return {
        imagesStore,
        userData:JSON.parse(localStorage.getItem('userData')),
        dialogVisible:false,
        editGroupData:{}, //编辑的分组数据
        deleteData:{},
        groupList:[],
        imageTagsArr:[],
        uploadImg:{}, // 
        uploadImgsArr:[],
        fileList:[],
        initFormData:{
          storeImageId:'',
          imageGroupId:'',
          imageTags:'', //
          imageTagName:'',
          imageUrl:'', //	
          isOpen:0, // 可见范围
        },
        formData:{
          storeImageId:'',
          imageGroupId:'',
          imageTags:'', //
          imageTagName:'',
          imageUrl:'', //	
          isOpen:0, // 可见范围
        },
        rules:{
          imageGroupId: [
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
            { max: 100, message: this.$t('formValidate.maxLength'), trigger: 'blur'}
          ], 
          imageUrl:[
            { required: true, message: this.$t('formValidate.required fields'), trigger: 'blur' },
          ]
        },
        
      }
    },
    created(){

    },
    mounted(){
      
    },
    watch: {
      editData: function(val) {
        console.log('--watch--editData',val)
        if(val && val.storeImageId){
          let data ={
            ...val,
            imageTags: val.imageTags || ''
          }
          this.formData = data
          this.switchTags()
        } else {
          let obj = {...this.initFormData}
          this.formData = obj
          this.uploadImgsArr = []
          this.switchTags()
        }
        
      }
    },
    methods: {
      // 打开弹窗
      open(){
        this.dialogVisible = true
        this.getData()
      },
      // 关闭弹窗
      handleClose(){
        this.dialogVisible = false
        this.fileList = []
        this.$refs.form.resetFields()
      },
      // 素材组 ---获取
      async getData(param){
        let userData = JSON.parse(localStorage.getItem('userData'))
        let storeId = localStorage.getItem('storeId')
        if(this.formData.storeImageId){
          // 编辑数据
          storeId = this.formData.storeId
        } 
        const params ={
          param: '',
          merchantId: userData.merchantId,
          storeId: storeId || userData.storeId,
          // storeQueryType: this.storeQueryType,
          isEffect: 0,
          storeName: '',
          current: 1,
          size: 9999,
          orders: []
        }
        try {
          let { data } = await getImageGroupList(params);
          console.log('获取图片数据',data);
          if(data.state){
            this.groupList = data.data
            if(param && param === 'add' && !this.formData.imageGroupId){
              this.formData.imageGroupId = data.data[0].imageGroupId
            }
          } else {
            this.$message.error(data.message);
          }
        } catch(err) {
          this.$message.error(err.message)
        }
      },
      // 素材组 ---添加
      handleAddGroup(){
        let editGroupData ={}
        if(this.formData.storeImageId){
          editGroupData.storeId = this.formData.storeId
        } else {
          editGroupData.storeId = localStorage.getItem('storeId')
        }
        this.editGroupData = editGroupData
        this.$refs.addGroup.open()
      },
      // 素材组 --添加成功
      addGroupSuccess(){
        this.getData('add')
      },
      // 素材组 ---编辑
      handleEdit(row){
        console.log('编辑组',row)
        this.editGroupData = row
        this.$refs.addGroup.open()
      },
      // 素材组 ---删除弹窗
      handleDelete(row){
        console.log('删除',row)
        this.deleteData = row
        // this.addGroupDialogVisible = true
        this.$refs.deleteConfirmDialog.open()
      },
      // 素材组 ---删除APi
      async imageDelete(){
        let { imageGroupId } = this.deleteData
        let params = {
          imageGroupId
        }
        try {
          const res = await deleteImageGroup(params)
          let response = res.data
          if(response.state){
            this.$message({
              message: this.$t('commonMessage.DeleteSucess'),
              type:'success',
            });
            // this.addGroupDialogVisible = false
            this.$refs.deleteConfirmDialog.close()
            this.getData()
            imagesStore.reloadImageGroupFn()
          } else{
            // this.addGroupDialogVisible = false
            this.$refs.deleteConfirmDialog.close()
            this.$message.error(response.message)
          }
        } catch(err) {
          this.$message.error(err.message)
        }
      },
      // beforeUpload
      beforeUpload(file){
        console.log('beforeUpload----file',file)
        const isLt3M = file.size / 1024 / 1024 < 3;
        if(!isLt3M){
          this.$message.error(this.$t('images.ImageSizeLimit'))
          return false
        }
      },
      // 上传图片
      async uploadImage(options){
        console.log('uploadImage----options',options)
        // let userData = JSON.parse(localStorage.getItem('userData'))
        const params = new FormData();
        params.append('fileType', '0');
        // fd.append('storeId', '1');
        params.append('file', options.file);
        let {uploadImgsArr,fileList} = this
        try {
          let { data } = await uploadImage(params);
          console.log('获取图片数据',data);
          if(data.state){
            let uploadImg = data.data
            uploadImgsArr.push({
              imageUrl:uploadImg.filePath, //	uploadImg.filePath
              imageSize:uploadImg.fileSize, //
              imageName: uploadImg.fileName, //
              imageSuffix: uploadImg.fileSuffix, // 图片后缀
              name:uploadImg.fileName,
              url:uploadImg.fileUrl+uploadImg.filePath
            })
            fileList.push({
              name:uploadImg.fileName,
              url:uploadImg.fileUrl+uploadImg.filePath
            })
            let formData = {
              ...this.formData,
              imageUrl:uploadImg.filePath, //	uploadImg.filePath
            }
            // console.log('formData',formData);
            this.uploadImg = uploadImg
            this.formData = formData
            this.uploadImgsArr= uploadImgsArr
            this.fileList = fileList
          } else {
            this.$message.error(data.message)
            // alert(data.message)
          }
        } catch (err) {
          const { message} = err;
          this.$message.error(message)
        }
      },
      handleChange(uploadFile, uploadFiles){
        console.log('handleChange----uploadFile, uploadFiles',uploadFile, uploadFiles)
      },
      // 删除图片
      removeImg(index){
        let { uploadImgsArr, fileList,formData} = this
        uploadImgsArr.splice(index,1)
        fileList.splice(index,1)
        if(uploadImgsArr.length === 0){
          formData.imageUrl = ''
          this.formData = formData
        }
        console.log('removeImg----uploadFile, uploadFiles',index)
      },
      // 素材标签--添加
      handleAddTag(){
        // imageTags
        // this.createImageTag()
        let { imageTagName } = this.formData
        let {imageTagsArr} = this
        let tag = trim(imageTagName)
        if(!tag){
          this.$message.error(this.$t('images.ErrorMessage1'))
          return
        }
        if(imageTagsArr.indexOf(tag) > -1){
          this.$message.error(this.$t('images.ErrorMessage2'))
          return
        }
        let arr =[...imageTagsArr]
        arr.push(tag)
        this.imageTagsArr = arr
        this.formData.imageTagName =''
      },
      // 素材标签---删除
      handleDeleteTag(tag){
        let imageTagsArr = [
          ...this.imageTagsArr
        ]
        let index = imageTagsArr.indexOf(tag)
        imageTagsArr.splice(index,1)
        this.imageTagsArr = imageTagsArr 
      },
      // 素材标签--创建素材api
      async createImageTag(){
        let userData = JSON.parse(localStorage.getItem('userData'))
        let { imageTagName, imageTags } = this.formData
        const params = {
          merchantId: userData.merchantId,
          storeId: userData.storeId,
          tagName: imageTagName,
          tagOrder: 0,
          isEffect: 0
        }
        try {
          let { data } = await createImageTag(params);
          console.log('创建素材标签',data);
          if(data.state) {
            let arr =[...imageTags]
            const obj = {
              imageTagId:data.data,
              tagName:imageTagName
            }
            arr.push(obj)
            this.formData.imageTags = arr
            this.switchTags()
            // this.groupList = data.data
          } else {
            this.$message.error(data.message);
          }
        } catch (error) {
          this.$message.error(error.message);
        }   
      },
      switchTags(){
        let { imageTags } = this.formData
        let imageTagsArr = []
        if(imageTags.length > 0){
          imageTagsArr = imageTags.split(',')
        }
        
        // if(imageTags.length >0){
        //   imageTagsArr = imageTags.map((item) => item.tagName)
        // }
        this.imageTagsArr = imageTagsArr
      },
      
      // 表单提交
      onSubmit(){
        loading = this.$loading({
          text:"loading...",
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        })
        this.$refs.form.validate( valid => {
          if (valid) {
            console.log('表单校验通过',)
            this.createImage()
          } else {
            loading.close()
            console.log('error submit!!');
            return false
          }
        })
      },
      // 请求--- 创建素材/更新素材
      async createImage(){
        let { storeImageId, imageGroupId, isOpen} = this.formData
        // let userData = this.userData
        let arr = this.imageTagsArr
        let imageTagIdsTemp =''
        if(arr.length > 0 ){
          imageTagIdsTemp = arr.join(',')
        }

        let params = {
          imageGroupId,
          imageTags:imageTagIdsTemp, //
          isOpen, //是否开放(0:所属门店可见/私有1:所属商户可见)
        }
        let res = null
        try {
          if(storeImageId){
            params.imageId = storeImageId
            res = await updateImage(params)
          } else {
            params.images = this.uploadImgsArr
            params.storeId = localStorage.getItem('storeId')
            res = await batchCreateImage(params)
          }
          let response = res.data
          if(response.state){
            let message = this.$t('commonMessage.CreateSucess')
            if(storeImageId){
              message = this.$t('commonMessage.ModifySucess')
            }
            this.$message({
              message: message,
              type:'success',
            });
            this.fileList = []
            this.uploadImgsArr =[]
            this.dialogVisible = false
            this.$emit('getData')
            loading.close()
          } else{
            loading.close()
            this.$message.error(response.message);
          }
        } catch (error) {
          loading.close()
          this.$message.error(error.message);
        }
        
      },
    }
  }
</script>
<style lang="scss" scoped>
  .systemManage-create.create-image{
    .form-box{
      width: 500px;
    }
    .file-list{
      display: flex;
    }
    .form-btn-section{
      margin-top: 0px;
    }
    .el-select,
    .el-input--small{
      width: 82%;
      margin-right: 10px;
    }
    ::v-deep .el-tag{
      margin-bottom: 10px;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      padding-right: 30px;
      // word-wrap: break-word;
      // word-break: break-all;
      .el-icon-close{
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    
  }
  .select-group{
    .table-icon  {
      padding:6px;
      cursor: pointer;
      font-size: 18px;
      color: #000;
    }
  }
</style>