<template>
  <div class="common-table-list common-table-list-images">
    <div class="location">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("Welcome")
      }}</router-link> 
      <span> > {{ $t('System Management')}} > </span>
      <span style="color:#3461FF">{{ $t('Images') }}</span>
    </div>
    <div class="hd card">
      <div class="handle-button-section">
        <el-button type="primary" size="small" @click="handleAdd">
          <i class="el-icon-plus" />
          {{$t('button.New')}}
        </el-button>
        <el-button type="danger" size="small" @click="handleBatchDel" :disabled="!selection()">
          <i class="el-icon-delete" />
          {{$t('button.Batch Delete')}}
        </el-button>
      </div>
    </div>
    <div class="bd card">
      <!--切换门店 -->
      <div class="table-header">
        <div>
          <span class="text">{{$t('images.Records')}}:{{pageParams.total}}</span>
          <template v-if="userData.userType ===203 || userData.userType ===204">
            <el-select v-model="searchParams.storeQueryType" @change="getData" placeholder="This Store" size="small">
              <el-option :label="$t('ThisStore')" value="2" />
              <el-option :label="$t('ZimenStore')" value="1" />
              <el-option :label="$t('AllStore')" value="0" />
            </el-select>
          </template>
        </div>
      </div>

      <!-- 表格 -->
      <el-table
        ref="normalTable"
        :data="dataList"
        style="width: 100%"
        @sort-change="handleSort"
        max-height="500"
      >
        <!-- <el-table-column width="50" fixed>
          <el-table-column type="selection" width="50">
            <template slot="header" slot-scope="scope"></template>
          </el-table-column>
        </el-table-column> -->
        <el-table-column align="center" type="selection" fixed>
          <el-table-column align="center" width="55" type="selection">
          </el-table-column>
        </el-table-column>
        <el-table-column prop="store_name"  min-width="130" :label="$t('images.Store name')" header-align="center">
          <el-table-column prop="storeName" min-width="130" align="center">
            <template slot="header" slot-scope="scope">
              <el-input size="mini" v-model="searchParams.storeName" @input="getData" placeholder="" clearable></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="image_name" min-width="150" :label="$t('images.Image')" sortable="custom" header-align="center">
          <el-table-column prop="imageName" min-width="150" align="center">
            <template #header>
              <el-input size="mini" v-model="searchParams.imageName" @input="getData" placeholder="" clearable></el-input>
            </template>
            <template #default="scope">
              <div class="show-img-bd">
                <div class="img-container">
                  <img @click="handleImgClick(scope.row)" :src="scope.row.imageUrl" class="show-img" />
                </div>
                <div>{{scope.row.imageName}}</div>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="image_group_id" min-width="100" :label="$t('images.Group')" header-align="center" >
          <el-table-column prop="groupName" min-width="100" align="center" >
            <template slot="header" slot-scope="scope">
              <!-- <el-input size="small" v-model="searchParams.groupName" @input="getData" placeholder=""></el-input> -->
              <el-select v-model="searchParams.groupId" placeholder=""  size="mini" @change="getData" clearable>
                <el-option
                  v-for="item in groupList"
                  :key="item.imageGroupId"
                  :label="item.groupName"
                  :value="item.imageGroupId"
                >
                  <div class="d-flex justify-content-between">
                    <div class="">{{item.groupName}}</div>
                    <div style="color:#ccc">{{item.storeName}}</div>
                  </div>
                </el-option>
              </el-select>

            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="imageTagsName" :label="$t('images.Tags')" header-align="center"> 
          <!-- <el-table-column prop="imageTagsName" label="Tags" sortable="custom">  -->
          <el-table-column prop="imageTags" align="center">
            <template slot="header" slot-scope="scope">
              <el-input size="mini" v-model="searchParams.tagName" @input="getData" placeholder="" clearable></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="image_size" :label="$t('images.Image Size')" min-width="120" sortable="custom" header-align="center">
          <el-table-column prop="imageSize" min-width="120" align="center">
            <template slot="header" slot-scope="scope">
              <el-input size="mini" v-model="searchParams.imageSize" @input="getData" placeholder="" clearable></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="image_suffix" :label="$t('images.Format')" sortable="custom" width="120" header-align="center">
          <el-table-column prop="imageSuffix" width="120" align="center">
            <template slot="header" slot-scope="scope">
              <el-input size="mini" v-model="searchParams.format" @input="getData" placeholder="" clearable></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="create_time" :label="$t('images.Create Time')" sortable="custom" width="230" header-align="center">
          <el-table-column prop="createTime" width="230" align="center">
            <template slot="header" slot-scope="scope">
              <el-date-picker
                v-model="searchParams.date"
                type="daterange"
                size="mini"
                @change="handleDateChange"
                placement="bottom-start"
                value-format="yyyy-MM-dd"
              />
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column fixed="right" :label="$t('images.Action')" width="120" header-align="center">
          <el-table-column  align="center" width="120" >
            <template slot="header" ></template>
            <template slot="default" slot-scope="{row}">
              <div class="operate">
                <span class="table-icon"  @click="handleDelete(row)">
                  <i class="el-icon-delete"></i>
                </span>
                <span class="table-icon"  @click="handleEdit(row)">
                  <i class="el-icon-edit-outline"></i>
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>

      <!-- 页码 -->
      <pagination :tableInfo="pageParams" @changePage="getData" />

    </div>

    <!-- 删除弹窗确认 -->
    <deleteConfirmDialog ref="deleteConfirmDialog" @onDeleteConfirm="imageDelete"></deleteConfirmDialog>

    <!-- 创建素材 -->
    <createImg 
      ref="createImg" 
      :editData="editData"
      @getData="getData">
    </createImg>

    <!-- 预览素材图片 -->
    <previewImg ref="previewImgRef"></previewImg>
  </div>
</template>
<script>
  import { getImageList, imageBatchDelete, getImageGroupList } from "@/libs/api/system-management-images";
  import {commonStore} from '@/store/global-select-store'
  import createImg from './components/createImg'
  import previewImg from './components/previewImg'
  import deleteConfirmDialog from '@/components/delete-confirm-dialog/index'
  import pagination from '@/components/pagination'

  import imagesStore  from './store/index'
  export default {
    name:'',
    components: {
      createImg,
      previewImg,
      deleteConfirmDialog,
      pagination
    },
    props: {
      location: {
        type: String,
        default: 'default',
      },
    },
    data() {
      return {
        imagesStore,
        commonStore,
        userData:JSON.parse(localStorage.getItem('userData')),
        dataList:[],
        groupList:[],
        editData:{},
        searchParams:{
          storeQueryType:'0', ////门店查询类型 ,0：所有门店，1：子门店，2：当前门店
          storeName:'',
          imageName:'',
          groupId:'',
          imageSize:'',
          tagName:'',
          format:'',
          date:[],
          orders:[],//['desc:image_url']
          startDate:'',
          endDate:'',
          isEffect:0
        },
        pageParams:{
          current:1,//当前页
          size:10,//单页显示最大数
          total:0,
          pageSizeArr:['10','20','50','100','200','500','1000'],
        },
        currentRow:{
          imageUrl:''
        },
        checked:false,
        loading:false
      }
    },
    created(){
      commonStore.setInjectCallBack(this.initData)
      imagesStore.setImageGroupFn(this.getImageGroupList)
      this.initData()
    },
    mounted(){
      
    },
    methods: {
      initData(){
        this.getData()
        this.getImageGroupList()
      },
      // 获取数据
      async getData(){
        let {
          imageName,
          storeName,
          groupId,
          imageSize,
          tagName,
          format,
          orders,
          startDate,
          endDate,
          isEffect,
          storeQueryType
        } = this.searchParams
        const params ={
          storeId: localStorage.getItem('storeId'),
          storeType: storeQueryType,
          imageName,
          storeName,
          groupId,
          imageSize,
          tagName,
          format,
          orders,
          startDate,
          endDate,
          isEffect,
          current: this.pageParams.current,
          size: this.pageParams.size //10，20，50，100. 
        }
        try {
          let { data } = await getImageList(params);
          console.log('获取图片数据',data);
          if(data.state){
            const list = [...data.data.records]
            this.dataList = list;
            this.pageParams.total = data.data.total
          } else {
            this.$message.error(data.message);
          }
        } catch (error) {
          this.$message.error(data.message);
        }
        
      },
      //获取 --- 素材组
      async getImageGroupList(param){
        let userData = JSON.parse(localStorage.getItem('userData'))
        let storeId = localStorage.getItem('storeId')
        let storeQueryType = this.searchParams.storeQueryType
        const params ={
          param: '',
          merchantId: userData.merchantId,
          storeId: storeId || userData.storeId,
          storeQueryType,
          isEffect: 0,
          storeName: '',
          current: 1,
          size: 9999,
          orders: []
        }
        try {
          let { data } = await getImageGroupList(params);
          if(data.state){
            this.groupList = data.data
          } else {
            this.$message.error(data.message);
          }
        } catch (error) {
          this.$message.error(error.message);
        }  
      },
      // size --change
      handleSizeChange(){
        this.pageParams.current = 1
        this.getData()
      },
      // 点击查看图片
      handleImgClick(data){
        // window.open(url)
        this.$refs.previewImgRef.open(data)
      },
      // 排序
      handleSort(column){
        console.log('排序',column)
        let arr = []
        // this.searchParams.orders = column.prop + '_' + column.order;
        if(column.order){
          let str1= column.order === "ascending" ? "asc" : "desc";
          str1 = str1 + ':'+ column.prop;
          arr.push(str1)
        } else {
          arr = []
        }
        
        this.searchParams.orders = arr;
        this.getData()
      },
      // 时间选择change
      handleDateChange(val){
        console.log('时间选择---',val)
        if(val){
          this.searchParams.startDate = val[0];
          this.searchParams.endDate = val[1];
        } else {
          this.searchParams.startDate = '';
          this.searchParams.endDate =  '';
        }
        
        this.getData()
      },
      // 新增
      handleAdd(){
        this.editData = {}
        this.$refs.createImg.open()
      },
      // 编辑
      handleEdit(row){
        console.log('编辑',row)
        this.editData = row
        this.$nextTick(() => {
          this.$refs.createImg.open()
        })
      },
      // 多选复选框---获取选中的数量
      selection() {
        return this.$refs.normalTable && this.$refs.normalTable.selection.length
      },
      // 删除  ---批量删除
      handleBatchDel(){
        const selection = this.$refs.normalTable.selection
        let imageIdsArr = selection.map(it => it.storeImageId)
        let deleteData ={
          imageIds:imageIdsArr.join(',')
        }
        console.log('deleteData',deleteData)
        this.deleteData = deleteData
        // this.dialogVisible =true
        this.$refs.deleteConfirmDialog.open()
      },
      // 删除  ---单个删除
      handleDelete(row){
        console.log('删除',row)
        let deleteData={
          ...row,
          imageIds:row.storeImageId
        }
        this.deleteData = deleteData
        // this.dialogVisible =true
        this.$refs.deleteConfirmDialog.open()
      },
      // 删除  --api删除素材
      async imageDelete(){
        let { imageIds } = this.deleteData
        let params = {
          imageIds:imageIds
        }
        try {
          const res = await imageBatchDelete(params)
          let response = res.data
          if(response.state){
            this.$message({
              message: this.$t('commonMessage.DeleteSucess'),
              type:'success',
            });
            // this.dialogVisible = false
            this.$refs.deleteConfirmDialog.close()
            this.getData()
          } else{
            this.$refs.deleteConfirmDialog.close()
            // this.dialogVisible = false
            this.$message.error(response.message)
          }
        } catch(error){
          this.$message.error(error.message)
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  // .common-table-list.common-table-list-images{
  //   ::v-deep .el-tag{
  //     margin-bottom: 10px;
  //   }
  // }
  .common-table-list-images ::v-deep {
    .el-table
      .el-table__fixed-header-wrapper
      .el-table__header
      .is-group
      tr:nth-of-type(2)
      th:nth-of-type(1)
      .cell {
        display: none;
      }
  }
  .common-table-list-images {
    .show-img-bd{
        .img-container{
          width:40px;
          height: 40px;
          margin: 0 auto;
          .show-img{
            cursor: pointer;
            width:100%;
            height: 100%;
            object-fit: contain;
          }
        }
        
      }
  }
</style>